<template>
    <div>
        <div v-if="loggedIn == false" id="login_container">
            <login></login>
        </div>
        <div v-else id="home">

            <div id="title" class="padding flex">
                <div style="flex: 1">
                    <h1 class="white">PrimeCalc</h1>
                    <h2 class="white">Willkommen zurück!</h2>                
                </div>
                <div class="flex">
                    <div style="margin: auto">
                        <!-- <p class="white">Angemeldeter Benutzer:</p> -->
                        <p><b class="white">{{$store.state.username}}</b></p>
                    </div>
                    <img class="pointer" @click="$store.dispatch('logout')" style="margin: auto 0 auto .5em" src="https://dashboard.pixelprime.de/assets/icons/light/logout.svg" alt="">
                </div>
            </div>

            <div id="new" class="padding card" to="/new">
                <router-link to="/new" style="text-decoration: none">
                    <h3 class="textspacing">Neue Kalkulation erstellen</h3>
                    <img class="padding_small dark radius" src="https://dashboard.pixelprime.de/assets/icons/documents.svg" alt="">
                </router-link>
            </div>

            <div id="load" class="padding card">
                <h3>Laden</h3>        
                <br>       
                <!-- <search :values="saves_existing" @filtered="saves_filtered = $event"></search> -->
                <loading v-if="!saves_existing" :msg="'Lade gespeicherte Kalkulationen.'"></loading>
                <div v-else>
                    <div class="padding radius dark">
                        <p><b></b></p>
                        <p><b>Name</b></p>
                        <p><b>Kunde</b></p>
                        <p><b>Ersteller</b></p>
                        <p><b>Änderungsdatum</b></p>
                        <p><b>Status</b></p>
                        <p></p>
                    </div>
                    <div v-for="(s, i) in saves_filtered" :key="i" class="padding_small radius pointer">
                        <img @click="loadCalculation(i)" src="https://kalkulation.primecalc.de/assets/images/icons/load.svg">
                        <p @click="loadCalculation(i)">{{s.name}}</p>
                        <p @click="loadCalculation(i)">{{s.customer.name}}</p>
                        <p @click="loadCalculation(i)">{{s.creator}}</p>
                        <p @click="loadCalculation(i)">{{s.lastEdited}}</p>
                        <p @click="loadCalculation(i)">{{s.state}}</p>
                        <img @click="deleteSave(i)" src="https://primecalc.de/assets/images/delete.svg" alt="">
                    </div>
                    <p v-if="!Object.keys(saves_existing).length">Keine Kalkulationen gefunden.</p>
                </div>
            </div>

        </div>
    </div>
</template>


<script>
// @ is an alias to /src

export default {
    name: "Home",
    components: {
        
    },
    data() {
        return {
            saves_filtered: 0,
            saves_loading: true,
            templates_loading: true,
        }
    },
    created() {
        this.$store.dispatch("saves_getAll")
    },
    computed: {
        loggedIn() {
            return this.$store.state.loggedIn
        },
        saves_existing() {
            return this.$store.getters.saves
        }
    },
    watch: {
        saves_existing() {
            this.saves_filtered = this.saves_existing
        }
    },
    methods: {
        deleteSave(id) {
            this.$store.commit("saves_delete", id)
        },
        loadCalculation(id) {
            document.cookie = `calculation_loaded=${id}; expires=${new Date()+1000*60}; path=/`;
            this.$router.push("/calculation/file")
            this.$store.dispatch("loadCalculation")
        }
        // loadCalculation(id) {
        //     const formData = new FormData();
        //     formData.append('method', "loadCalculation");
        //     formData.append('id', id);
        //     fetch(this.$store.getters.path_data, {
        //         method: "POST",
        //         body: formData
        //     }).then(result => {
        //         return result.json()
        //     }).then(result => {
        //         if (result[0]) {
        //             this.$router.push("/calculation/file")
        //             setTimeout(() => {
        //                 document.location.reload()
        //             }, 100);
        //         }
        //         else {
        //             this.$router.push("/")
        //         }
        //     })
        // }
    }
};
</script>

<style scoped>
    .white{color: #dedede}
    #home{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto 1fr 4em;
        grid-row-gap: 2em;
        width: 100vw;
        height: 100vh;
    }
    #sidemenu{
        /* grid-area: 1/1/4/2; */
    }
    #title{
            margin-bottom: 2em;
            background-color: #22252D;
            padding: 1em 8em;
            grid-column-gap: 4em;
        }
    #new{
            margin: 0 8em;
        }
        #new>a>img{
            height: 3em;
            width: 3em;
        }
    #templates{
            /* grid-area: 2/2/3/3; */
            margin: 0 8em;
        }
        #templates>div{
            display: grid;
            grid-template-columns: repeat(auto-fit, 12em);
            grid-column-gap: 1em;
        }
        #templates>div>p{
            word-wrap: break-word;
        }
    #load{
            /* grid-area: 3/2/4/3; */
            margin: 0 8em;
            height: auto;
        }
        #load>div{overflow-x: scroll;}
        #load>div>div{
            transition: .25s;
            display: grid;
            grid-template-columns: 4em 1fr 1fr 1fr 1fr .5fr auto;
        }
        #load>div>div>img{
            height: 24px;
        }
        #load>div>div>p{
            margin: auto 0;
        }
        #load>div>div:nth-child(odd){background-color: rgb(247, 247, 247);}
        #load>div>div:nth-child(even){background-color: rgb(252, 252, 252);}
        #load>div>div:first-child{background-color: #f3f3f3!important;}
        #load>div>div:hover{background-color: rgb(209, 224, 245);}

</style>